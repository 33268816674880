<template>
    <div class="container">
        <HomeTaskCount :taskCount="taskCount" headline="Sinu tööd"
            description="Tööd, mis on määratud Sinule." link="/tasks-unfinished/" numberClass="div-green" />

        <HomeTaskCount :taskCount="taskAgreedCount" headline="Kokkulepitud tööd"
            description="Kliendiga kokku lepitud tööd." link="/tasks-agreed/" numberClass="div-red" />

        <HomeTaskCount :taskCount="taskInProgressCount" headline="Pooleli tööd"
            description="Palun lõpeta esimesel võimalusel." link="/tasks-inprogress/" numberClass="div-yellow" />

        <HomeTaskCount v-if="taskOpenCount !== null" :taskCount="taskOpenCount" headline="Vabad tööd"
            description="Valik tehnikule määramata töid." link="/tasks-open/" numberClass="div-purple" />

<!--         <HomeTaskCount :taskCount="taskCriticalCount" headline="Kriitilised tööd"
            description="Töö tegemine esimesel võimalusel." link="/tasks-critical/" numberClass="div-red" /> -->

<!--         <HomeTaskCount :taskCount="taskTodayCount" headline="Tänased tööd"
            description="Töö tegemine esimesel võimalusel." link="/tasks-today/" numberClass="div-green" /> -->

<!--         <HomeTaskCount :taskCount="taskTomorrowCount" headline="Homsed tööd"
            description="Töö tegemine esimesel võimalusel." link="/tasks-tomorrow/" numberClass="div-green" /> -->


        <OnCallList/>
    </div>
</template>
  
<script>
import axios from '@/services/axios';
import HomeTaskCount from '../components/HomeTaskCount.vue';
import OnCallList from '@/components/OnCallList.vue';

export default {
    name: 'HomeView',
    components: {
        HomeTaskCount,
        OnCallList
    },
    data() {
        return {
            taskCriticalCount: 0,
            taskTodayCount: 0,
            taskTomorrowCount: 0,
            taskInProgressCount: 0,
            taskCount: 0,
            taskOpenCount: null,
            taskAgreedCount: 0,
        };
    },
    mounted() {
        this.fetchTasks();
        document.body.style.backgroundColor = 'white';
    },
    methods: {
        async fetchTasks() {
            try {
                const [taskSummaryResponse] = await Promise.all([
                    axios.get(`tasks/summary?users=${this.currentUser.id}`)
                ]);
                console.log(taskSummaryResponse);
                this.taskCriticalCount = taskSummaryResponse.data.data.critical;
                this.taskTodayCount = taskSummaryResponse.data.data.today;
                this.taskTomorrowCount = taskSummaryResponse.data.data.tomorrow;
                this.taskInProgressCount = taskSummaryResponse.data.data.in_progress;
                this.taskCount = taskSummaryResponse.data.data.unfinished;
                this.taskAgreedCount = taskSummaryResponse.data.data.agreed;
                this.taskOpenCount = taskSummaryResponse.data.data.open;

            } catch (error) {
                console.error('Error fetching tasks:', error);
                // Handle error
            }
        }
    },
    computed: {
        currentUser() {
            const user = localStorage.getItem('user');
            return user ? JSON.parse(user) : null;
        }
    },
    beforeUnmount() {
        document.body.style.backgroundColor = '';
    }
};
</script>
<style scoped>
.frame .t-ext {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
    width: 225px;
}

.frame .text-wrapper {
    color: #2f174c;
    font-family: "Fira Sans-Medium", Helvetica;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 28.6px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.frame .label-text {
    color: #333f4c;
    font-family: "Fira Sans-Regular", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.frame .right-arrow {
    height: 32px;
    margin-left: -12px;
    position: relative;
    width: 32px;
}
</style>